import React, { useMemo } from "react";
import { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import topBG from "../../images/explore-gallery/top-bg.svg";
import topLeaf from "../../images/explore-gallery/top-leaf.svg";
import Navbar from "../navbar/Navbar";
import "../explore-gallery/Gallery.css";
import topPath from "../../images/explore-gallery/top-btm-path.png";
import line1 from "../../images/explore-gallery/line-1.png";
import line2 from "../../images/explore-gallery/line-1.png";
import birdImage from "../../images/explore-gallery/bird.svg";
import rightLeaf from "../../images/explore-gallery/sec-right-leaf.svg";
import secBtm from "../../images/sec-bottom.svg";
import leaf from "../../images/insight-top-2.svg";
import btm from "../../images/map-btm.svg";
import FooterPage from "../footer-page/FooterPage";
import close from "../../images/explore-gallery/close.svg";
import right from "../../images/explore-gallery/right.svg";
import left from "../../images/explore-gallery/left.svg";
import ReactPannellum from "react-pannellum";
import polygon from "../../images/explore-gallery/polygon.svg";
import VideoPlayer from "./VideoPlayer";

const Gallery = () => {
	const location = useLocation();
	const canonicalUrl = `${window.location.origin}${location.pathname}`;
	const [activeSection, setActiveSection] = useState("PROGRESS");
	const [currentImageIndex, setCurrentImageIndex] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isScrolling, setIsScrolling] = useState(true);

	const config = {
		autoLoad: true,
		compass: false,
		showFullscreenCtrl: true,
		pitch: 0,
		yaw: 0,
		hfov: 110,
		minHfov: 50,
		maxHfov: 120,
		autoRotate: -2,
		showZoomCtrl: false,
	};
	const mixedMedia = useMemo(
		() => [
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fcloud_house.jpeg?alt=media&token=f341a1d2-45ff-432d-b7cd-3ae843b3074a",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fcloud_house_thumb.jpeg?alt=media&token=7ec8be82-eed7-4fef-a926-4e9d8768b959",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-1.svg?alt=media&token=764cfad8-1c93-43d7-844c-2b45d491bab7",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-thumb-1.jpg?alt=media&token=b9c6ffe5-78f9-414a-bec4-8674b03eee6a",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-2.svg?alt=media&token=ed22f5ca-49e5-42ca-819e-73eff85b36ed",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-thumb-2.jpg?alt=media&token=1db35c84-7e65-4a14-bd90-84159d6a9902",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-3.svg?alt=media&token=11fb96c4-6807-44d1-b518-53fabf54b75d",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fimages%2Fpic-thumb-3.jpg?alt=media&token=c343977f-57bb-4310-ac9e-6aafecd89bd8",
			},
			{
				type: "video",
				src: "https://drive.google.com/file/d/17rud30OgqMRe_5WEMiXztAJpmTMNJ4b3/preview",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fvideos%2FVIDEO-4-THUMB.jpg?alt=media&token=f332ddde-4e15-44e4-aa38-4cf5a7d9aae5",
			},
			{
				type: "video",
				src: "https://drive.google.com/file/d/1uqTuzTCpv-fRAt8-Xq04J3rHcidAC8IV/preview",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fvideos%2FDJI_0714%20(3).jpg?alt=media&token=a098ef71-7f71-4917-b594-9dcfd1827175",
			},
			{
				type: "video",
				src: "https://drive.google.com/file/d/17KDt8wIRqWQz55WcvuWx9GBRbK5UE6Xj/preview",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fvideos%2Fvideo-thumb-1.jpg?alt=media&token=63d7963e-0d8f-4fef-9d95-d746b4a515e5",
			},
			{
				type: "video",
				src: "https://drive.google.com/file/d/1AtcXvIbGXvRey1uwu53gAmDhprvYc5Bi/preview",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fvideos%2Fvideo-thumb-5.jpg?alt=media&token=292f2ef4-ee78-4fc9-87da-cc85339e0c15",
			},
			{
				type: "video",
				src: "https://drive.google.com/file/d/1d0MAtTFWryXPxDkPXEaTTUYFp0T8aDLR/preview",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fvideos%2Fvideo-thumb-6.jpg?alt=media&token=27091324-3326-4507-8b4d-84d928a883b9",
			},
			// { type: "360", src:"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2F360images%2Fimg-1.jpg?alt=media&token=cbe9a192-0c6e-443c-b4f2-4f0548915624", thumbnail:"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2F360images%2Fimg-thumb-1.jpg?alt=media&token=1422431b-3a85-4994-a4ff-31c6c9286944"},
		],
		[]
	);

	const progressiveMedia = useMemo(
		() => [
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-1.jpeg?alt=media&token=55647990-ac39-4ccc-aeaa-9c07315292f1",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-1.jpg?alt=media&token=c2c7394d-b1f3-45d5-8323-4f47c3eedbd3",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-2.jpeg?alt=media&token=4fd832f0-2578-4355-ae71-376f6c1aedfc",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-2.jpg?alt=media&token=bdde04f8-2aa5-4539-b560-b0734e87840e",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-3.jpeg?alt=media&token=4fd6b59f-a102-4a79-9fe5-936dd52708e6",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-3.jpg?alt=media&token=ac2422f7-438f-4d26-9e68-b0c54e3a0254",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-4.jpeg?alt=media&token=16b3c843-aaff-41f9-b317-bd6e97324e0e",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-4.jpg?alt=media&token=364f6bfc-9f89-41cf-aa98-540261511117",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-5.jpeg?alt=media&token=ea11ced2-bd6f-46ac-94a7-ad8bce8f7cc0",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-5.jpg?alt=media&token=450b725a-72c3-434d-bf8f-9d6f2c99bdff",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-6.jpeg?alt=media&token=cd6484f2-31cc-4422-b98b-314fdeaa7615",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-6.jpg?alt=media&token=c8292c11-0cd5-41ac-b7bb-49d4fae13cb2",
			},
			{
				type: "image",
				src: "https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-7.jpeg?alt=media&token=6fa8c4c7-1075-4116-9ba0-4df3a19f88f3",
				thumbnail:
					"https://firebasestorage.googleapis.com/v0/b/demand-harvest-prod-7db62.appspot.com/o/public%2Fjungle_view_homes%2Fprogressive%2Fpro-thumb-7.jpg?alt=media&token=100e7420-54cf-429e-b507-ecfde95da88f",
			},
		],
		[]
	);
	const handleClick = (section) => {
		setActiveSection(section);
	};
	const handleScroll = () => {
		if (!isScrolling) {
			setIsScrolling(true);
		}
		// clearTimeout(scrollRef.current);
		// scrollRef.current = setTimeout(() => setIsScrolling(false), 200);
	};
	const openModal = (index) => {
		setCurrentImageIndex(index);
		setIsModalOpen(true);
	};

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setCurrentImageIndex(null);
	}, []);
	// Get the current data based on the active section
	const getCurrentData = useCallback(() => {
		switch (activeSection) {
			case "IMAGES":
				return mixedMedia.filter((item) => item.type === "image");
			case "VIDEO":
				return mixedMedia.filter((item) => item.type === "video");
			case "360 IMAGES":
				return mixedMedia.filter((item) => item.type === "360");
			case "PROGRESS":
				return [...progressiveMedia].reverse();
			default:
				return [];
		}
	}, [activeSection, mixedMedia, progressiveMedia]);

	const prevImage = () => {
		const currentData = getCurrentData();
		setCurrentImageIndex((prev) =>
			prev === 0 ? currentData.length - 1 : prev - 1
		);
	};

	const nextImage = () => {
		const currentData = getCurrentData();
		setCurrentImageIndex((prev) =>
			prev === currentData.length - 1 ? 0 : prev + 1
		);
	};
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") {
				closeModal();
			}
		};

		if (isModalOpen) {
			document.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [isModalOpen, closeModal]);

	return (
		<>
			<Helmet>
				<title>Property</title>
				<meta
					name="description"
					content="Learn about Jungle View Homes—our vision, commitment to quality, and dedication to creating a secure, eco-friendly, and luxurious living experience."
				/>
				<link rel="canonical" href={canonicalUrl} />
			</Helmet>
			<div
				className={`gallery_page ${isModalOpen ? "modal-blur" : ""}`}
				style={isModalOpen ? { margin: "-50px" } : {}}
			>
				<div className="first_container">
					<div className="overlay"></div>

					{/* Navbar for navigation */}
					<header className="top_nav_gallery">
						<Navbar />
					</header>

					{/* Hero Section */}
					<div className="top_bg">
						<img
							src={topBG}
							className="img-fluid"
							alt="Luxury Property and Land Gallery"
							loading="lazy"
						></img>
					</div>

					<div className="top_leaf">
						<img
							src={topLeaf}
							className="img-fluid"
							alt="Decorative Leaf"
							loading="lazy"
						></img>
					</div>

					{/* Main content section */}
					<div className="container top_text_1 text-center">
						<h1 className="first_text">
							<span className="explore">Explore Our </span>
							<span className="highlight">Property & Land Gallery</span>
						</h1>

						<p className="sec_text">
							Browse our curated collection of stunning real estate properties
							and premium land locations. Our high-quality images and videos
							showcase each location’s unique charm, helping you discover your
							next investment or dream home.
						</p>
					</div>

					<div className="top_path">
						<img
							src={topPath}
							className="img-fluid"
							alt="Scenic Landscape Path"
							loading="lazy"
						></img>
					</div>
				</div>

				<div className="sec_container">
					{/* Grid View */}
					<div className="grid_view">
						<div className="container grid_photo">
							<figure className="line_1">
								<img
									src={line1}
									className="img-fluid"
									alt="Decorative Line One"
									loading="lazy"
								/>
							</figure>

							{/* Section Tabs */}
							<div className="container">
								<nav className="row grid_top">
									{["PROGRESS", "IMAGES", "VIDEO", "360 IMAGES"].map(
										(section) => (
											<div
												key={section}
												className={`col-sm section text_grid ${
													activeSection === section ? "active" : ""
												}`}
												onClick={() => handleClick(section)}
												role="button"
												aria-label={`View ${section}`}
											>
												<div className="active_sec">
													{section}
													{activeSection === section && (
														<div>
															<img
																src={birdImage}
																alt="Bird Icon"
																className="img-fluid"
																loading="lazy"
															/>
														</div>
													)}
												</div>
											</div>
										)
									)}
								</nav>
							</div>

							<figure className="line_2">
								<img
									src={line2}
									className="img-fluid"
									alt="Decorative Line Two"
									loading="lazy"
								/>
							</figure>

							{/* Media Content */}
							<div className="grid_images">
								<div
									className={`vertical-scrollable ${
										isScrolling ? "scrolling" : ""
									}`}
									onScroll={handleScroll}
								>
									<div className="container">
										<div className="row row_images" key={activeSection}>
											{getCurrentData().length === 0 ? (
												<div className="col-12 text-center emptyMedia">
													<p>
														{activeSection === "VIDEO"
															? "No Videos Available"
															: "No Images Available"}
													</p>
												</div>
											) : (
												getCurrentData().map((item, index) => (
													<figure
														className="col-sm-4"
														style={{ position: "relative" }}
														key={`${item.type}-${index}`}
													>
														<img
															src={item.thumbnail}
															className="img-fluid"
															alt={`${item.type} Thumbnail ${index + 1}`}
															onClick={() => openModal(index)}
															loading="lazy"
														/>
														{(item.type === "video" || item.type === "360") && (
															<div
																className="play_btn"
																onClick={() => openModal(index)}
																role="button"
															>
																<img
																	src={polygon}
																	className="img-fluid"
																	alt="Play Button Icon"
																	loading="lazy"
																/>
															</div>
														)}
													</figure>
												))
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Right Decorative Leaf */}
						<figure className="right_tree">
							<img
								src={rightLeaf}
								className="img-fluid"
								alt="Decorative Leaf Right"
								loading="lazy"
							/>
						</figure>
					</div>

					{/* Bottom Decoration */}
					<figure className="secBottom">
						<img
							src={secBtm}
							className="img-fluid"
							alt="Bottom Decoration"
							loading="lazy"
						/>
					</figure>
				</div>
				<div className="third_container">
					{/* Decorative Leaf Image */}
					<div className="third_top_leaf">
						<img
							src={leaf}
							className="img-fluid"
							alt="Green leaf representing nature"
						/>
					</div>

					{/* Text Content Section */}
					<div className="container third_text">
						<h1 className="text_1">
							<span className="highlight">Explore Our Paradise</span>
						</h1>
						<p className="text_2">
							At <strong>Jungle View Homes</strong>, each villa is a
							masterpiece, designed to harmonize with the natural landscape
							without disrupting its beauty. Every home is uniquely tailored to
							fit the specific site conditions, preserving the lush surroundings
							and enhancing the scenic environment. Here, architecture meets
							nature seamlessly, allowing you to live luxuriously while being
							one with the mountain’s serenity.
						</p>
					</div>

					{/* Bottom Path Image */}
					<div className="third_btm_pic">
						<img
							src={btm}
							className="img-fluid"
							alt="Scenic mountain path view"
						/>
					</div>
				</div>
				<div className="fourth_container">
					<FooterPage />
				</div>
			</div>
			{isModalOpen && (
				<>
					<div className="modal-overlay img_outer" tabIndex="-1" role="dialog">
						<div className="" role="document">
							<div className="">
								<div className="">
									<div data-bs-ride="carousel">
										<div className="carousel-inner">
											{getCurrentData().map((item, index) => (
												<div
													key={index}
													className={`carousel-item carousel_img ${
														index === currentImageIndex ? "active" : ""
													}`}
												>
													{item.type === "image" && (
														<img src={item.src} alt={`Slide-${index + 1}`} />
													)}
													{item.type === "video" &&
														index === currentImageIndex && (
															<div>
																{/* <div  style={{width: '640', height: '480', position: 'relative'}}>
    <iframe  src={item.src} width="640" height="480" frameBorder="0" scrolling="no" seamless="" allow="autoplay; fullscreen" allowfullscreen ></iframe>
    <div style={{width: '80px', height: '80px', position: 'absolute', opacity: '0', right: '0px', top: '0px'}}>&nbsp;</div>
  </div> */}
																<VideoPlayer videoId={item.src}></VideoPlayer>
																{/* <iframe
        src={item.src}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      /> */}
																{/* <video
    src={item.src}
    controls
    preload="auto"
    className="img-fluid"
    alt={`Video-${index + 1}`}
    autoPlay={index === currentImageIndex} 
    onLoadedData={(e) => {
      if(index === currentImageIndex){
      e.target.play()}}}
  /> */}
															</div>
														)}
													{item.type === "360" && (
														<div className="page-pannellum car_pannellum">
															<ReactPannellum
																id={`pano-${index}`}
																sceneId={`scene-${index}`}
																imageSource={item.src}
																config={config}
																key={currentImageIndex}
																style={{ objectFit: "cover" }}
															/>
														</div>
													)}
												</div>
											))}
										</div>
										<div className="button_img">
											<div className="left_arrow">
												<img
													src={left}
													className="img-fluid"
													alt="Left Arrow"
													onClick={(e) => {
														e.stopPropagation();
														prevImage();
													}}
												></img>
											</div>
											{/* <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-slide="prev"
                      onClick={(e) => {
                        e.stopPropagation();
                        prevImage();
                      }}
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button> */}
										</div>
										{/* <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-slide="next"
                    onClick={(e) => {
                      e.stopPropagation();
                      nextImage();
                    }}
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button> */}
										<div className="right_arrow">
											<img
												src={right}
												className="img-fluid"
												alt="Right Arrow"
												onClick={(e) => {
													e.stopPropagation();
													nextImage();
												}}
											></img>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<img
								src={close}
								className="img-fluid"
								onClick={closeModal}
								alt="close"
							></img>
						</div>
					</div>
				</>
			)}
		</>
	);
};
export default Gallery;
