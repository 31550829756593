import React from "react";
import nestImg from "../../images/icon-nest.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import { useLocation, Link } from "react-router-dom";

function Navbar() {
	const location = useLocation();

	// Determine the active section based on the current path
	const getActiveSection = () => {
		switch (location.pathname) {
			case "/":
				return "home";
			case "/property":
				return "property";
			case "/contact":
				return "contact";
			case "/about":
				return "about_us";
			case "/services":
				return "our_services";

			default:
				return "";
		}
	};

	const activeSection = getActiveSection();

	return (
		<header>
			<nav className="navbar navbar-expand-lg">
				<div className="container top_navbar">
					{/* Logo */}
					<div className="nav_icon">
						<a href="/">
							<img src={nestImg} alt="Nest Icon" className="img-fluid " />
						</a>
					</div>
					{/* Navigation Links */}
					<div className="nav_list">
						<ul className="d-flex mx-auto list-inline list-unstyled ">
							<li
								className={`list-inline-item ${
									activeSection === "home" ? "active" : ""
								}`}
							>
								<Link to="/"> Home</Link>
							</li>
							<li
								className={`list-inline-item ${
									activeSection === "property" ? "active" : ""
								}`}
							>
								<Link to="/property">Gallery</Link>
							</li>
							<li
								className={`list-inline-item ${
									activeSection === "about_us" ? "active" : ""
								}`}
							>
								<Link to="/about">About Us</Link>
							</li>
							<li
								className={`list-inline-item ${
									activeSection === "our_services" ? "active" : ""
								}`}
							>
								<Link to="/services"> Our Services</Link>
							</li>
						</ul>

						{/* Contact Us Button */}
					</div>
					<div className="d-flex">
						<button className="contact_us_top">
							<Link to="/contact" className="">
								CONTACT US
							</Link>
						</button>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default Navbar;
