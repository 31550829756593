import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../contact-us/Contact.css";
import "bootstrap/dist/css/bootstrap.min.css";
import top from "../../images/contact/contact-top.svg";
import Navbar from "../navbar/Navbar";
import borderBox from "../../images/contact/contact-outline.svg";
import mail from "../../images/contact/contact-mail.svg";
import whatsapp from "../../images/contact/contact-whatsapp.svg";
import face from "../../images/footer-page/link-fb.png";
import instagram from "../../images/footer-page/link-instagram.png";
import uTube from "../../images/footer-page/link-tube.png";
import contactBtm from "../../images/contact/contact-btm.svg";
import mapBottom from "../../images/map-btm.svg";
import FooterPage from "../footer-page/FooterPage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Contact = () => {
	const location = useLocation();
	const canonicalUrl = `${window.location.origin}${location.pathname}`;
	const [formLoading, setFormLoading] = useState(true);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		mobile: "",
		city: "",
		address: "",
	});
	const [submitted, setSubmitted] = useState(false);
	const [errors, setErrors] = useState({});
	const handleBlur = (e) => {
		const { name, value } = e.target;

		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };

			if (name === "name" && !value.trim()) {
				newErrors.name = "Required.";
			}

			if (name === "city" && !value.trim()) {
				newErrors.city = "Required.";
			}

			if (name === "mobile") {
				if (!value.trim()) {
					newErrors.mobile = "Required.";
				}
				//  else if (!/^\d{10}$/.test(value)) {
				//   newErrors.mobile = "Invalid";
				// }
				else {
					delete newErrors.mobile; // Clear error if valid
				}
			}

			return newErrors;
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		// Update formData state
		setFormData({ ...formData, [name]: value });

		// Clear error for the field if it becomes valid
		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			if (name === "name" && value.trim()) {
				delete newErrors.name;
			}
			if (name === "city" && value.trim()) {
				delete newErrors.city;
			}
			if (name === "mobile") {
				if (value.trim() && /^\d{10}$/.test(value)) {
					delete newErrors.mobile;
				} else if (!value.trim()) {
					newErrors.mobile = "Required.";
				}
				// else {
				//   newErrors.mobile = "Invalid";
				// }
			}
			return newErrors;
		});
	};

	const validateForm = () => {
		const newErrors = {};
		if (!formData.name.trim()) newErrors.name = "Required.";
		if (!formData.city.trim()) newErrors.city = "Required.";
		if (!formData.mobile.trim()) {
			newErrors.mobile = "Required.";
		} else if (!/^\d{10}$/.test(formData.mobile)) {
			newErrors.mobile = "Invalid";
		}
		return newErrors;
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		const formDataToSubmit = {
			name: formData.name,
			email: formData.email,
			mobile: formData.mobile,
			city: formData.city,
			address: formData.address,
		};

		try {
			// Make API call to Firebase Function using axios
			const response = await axios.post(
				"https://us-central1-demand-harvest-prod-7db62.cloudfunctions.net/submitContactForm",
				formDataToSubmit
			);

			if (response.status === 200) {
				setFormData({
					name: "",
					email: "",
					mobile: "",
					city: "",
					address: "",
				});
				setSubmitted(true);
				setErrors({});
				console.log("Form Submitted Successfully");
			} else {
				console.error("Submission Error:", response.data.error);
			}
		} catch (error) {
			console.error("Error submitting form:", error);
		} finally {
			setTimeout(() => {
				setSubmitted(false);
			}, 3000);
		}
	};

	const handleFormLoad = () => {
		setFormLoading(false);
	};
	return (
		<>
			<Helmet>
				<title>Contact</title>
				<meta
					name="description"
					content="Get in touch with Jungle View Homes for inquiries, support, or to learn more about our services. We're here to assist you!"
				/>
				<link rel="canonical" href={canonicalUrl} />
			</Helmet>
			<div className="contact_first_container">
				<div className="contact_navbar">
					<Navbar />
				</div>
				<div className="top_image">
					<img src={top} className="img-fluid" alt="Path"></img>
				</div>
				{formLoading && (
					<div className="form_skeleton">
						<Skeleton height={0} />
					</div>
				)}
				{!formLoading && (
					<div className="form_container">
						<div className="container inside_box">
							<div className="row">
								<div className="col-5 box_1 ">
									<div className="visit_box">
										<div className="top_text">
											<h1>
												Come &{" "}
												<span style={{ color: "#FFDD67" }}>
													Visit our place!
												</span>
											</h1>
										</div>
										<div className="link_details">
											<div className="location">
												<div className="mail_contact">
													<div className="details_image">
														<img
															src={mail}
															className="img-fluid"
															alt="Mail"
														></img>
													</div>
													<div className="details_text">
														<h1>Location</h1>
													</div>
												</div>
											</div>
											<p className="mail_text">
												ANCHETTY
												<br />
												KRISHNAGIRI
												<br />
												TAMIL NADU-635102
											</p>
											<div className="write">
												<div className="details_image">
													<img
														src={mail}
														className="img-fluid"
														alt="Mail"
													></img>
												</div>
												<div className="details_text">
													<h1>Write us</h1>
												</div>
											</div>
											<p className="mail_text">info@jungleviewhomes.com</p>
											<div className="general">
												<div className="details_image">
													<img
														src={whatsapp}
														className="img-fluid"
														alt="WhatsAPP"
													></img>
												</div>
												<div className="details_text">
													<h1>General Enquiry</h1>
												</div>
											</div>
											<p className="mail_text">+91 8867064507</p>
											<div className="media">
												<div className="details_text">
													<h1>Social Media</h1>
												</div>
												<div className="media_links">
													<div className="link_outline ">
														<div className="contact_social_link_1">
															<a
																href="https://www.facebook.com/people/Jungle-View-Homes/61571291915038/"
																target="_blank"
																rel="noopener noreferrer"
																aria-label="Follow Jungle View Homes on Facebook"
																title="Follow Jungle View Homes on Facebook"
															>
																<img
																	src={face}
																	alt="Facebook logo"
																	className="img-fluid"
																/>
																<span className="visually-hidden">
																	Follow Jungle View Homes on Facebook
																</span>
															</a>
														</div>
													</div>
													<div className="link_outline">
														<div className="contact_social_link_2">
															<a
																href="https://www.instagram.com/jungleviewhomes/"
																target="_blank"
																rel="noopener noreferrer"
																aria-label="Follow Jungle View Homes on Instagram"
																title="Follow Jungle View Homes on Instagram"
															>
																<img
																	src={instagram}
																	alt="Instagram logo"
																	className="img-fluid"
																/>
																<span className="visually-hidden">
																	Follow Jungle View Homes on Instagram
																</span>
															</a>
														</div>
													</div>

													<div className="link_outline">
														<div className="contact_social_link_5">
															<a
																href="www.youtube.com"
																target="_blank"
																rel="noopener noreferrer"
																aria-label="Subscribe to Jungle View Homes on YouTube"
																title="Subscribe to Jungle View Homes on YouTube"
															>
																<img
																	src={uTube}
																	alt="YouTube logo"
																	className="img-fluid"
																/>
																<span className="visually-hidden">
																	Subscribe to Jungle View Homes on YouTube
																</span>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-7 box_2">
									<div className="contact_box">
										<div className="border_box">
											<img
												src={borderBox}
												className="img-fluid"
												alt="Border-Box"
											></img>
											<div className="contact_content">
												<form>
													<div className="form">
														<div className="title_box">
															<h1>CONTACT US TOADY</h1>
														</div>
														<div className="form_details">
															<form>
																<div className="form_name">
																	<label for="name" className="userLabel">
																		Name<span className="mandatory">*</span>
																	</label>
																	<input
																		type="text"
																		name="name"
																		className={`userField ${
																			errors.name ? "errorField" : ""
																		}`}
																		placeholder="Enter Name"
																		value={formData.name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																	/>
																</div>
																{errors.name && (
																	<p className="error">{errors.name}</p>
																)}

																<div className="form_email">
																	<label for="emailID" className="userLabel">
																		E-mail
																	</label>
																	<input
																		type="email"
																		name="email"
																		className="userField"
																		placeholder="Enter E-mail"
																		value={formData.email}
																		onChange={handleChange}
																	/>
																</div>
																<div className="form_mobile">
																	<label for="mobile" className="userLabel">
																		Mobile Number
																		<span className="mandatory">*</span>
																	</label>
																	<input
																		type="number"
																		name="mobile"
																		className={`userField ${
																			errors.mobile ? "errorField" : ""
																		}`}
																		placeholder="Enter Mobile Number"
																		value={formData.mobile}
																		onChange={handleChange}
																	/>
																</div>
																{errors.mobile && (
																	<p className="error">{errors.mobile}</p>
																)}

																<div className="country_city">
																	<div className="form_city">
																		<label for="city" className="userLabel">
																			city<span className="mandatory">*</span>
																		</label>
																		<input
																			type="text"
																			name="city"
																			className={`userField ${
																				errors.city ? "errorField" : ""
																			}`}
																			placeholder="Enter city"
																			value={formData.city}
																			onChange={handleChange}
																		/>
																		{errors.city && (
																			<p className="error">{errors.city}</p>
																		)}
																	</div>

																	<div className="form_address">
																		<label for="address" className="userLabel">
																			Address
																		</label>
																		<textarea
																			name="address"
																			className="userField"
																			placeholder="Enter Address"
																			value={formData.address}
																			onChange={handleChange}
																		/>
																	</div>
																</div>

																<div
																	className="submit_btn"
																	onClick={handleSubmit}
																>
																	<button
																		type="submit"
																		className="btn form_sub_btn"
																	>
																		SUBMIT
																	</button>
																</div>
																{submitted && (
																	<p className="successMessage">
																		Thanks for submitting!
																	</p>
																)}
															</form>
														</div>

														{/* <div className="form_footer">
  <p>By continuing, you agree to our T&C and Privacy Policy</p>
</div> */}
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				<img
					src={borderBox}
					alt="Preloading Form"
					style={{ display: "none" }}
					onLoad={handleFormLoad}
				></img>
			</div>
			<div className="second_contact_container">
				<div className="hide_func"></div>
				<div className="contact_btm">
					<img src={contactBtm} className="img-fluid" alt="Path"></img>
				</div>

				<div className="contact_map">
					<iframe
						src="https://www.google.com/maps/embed/v1/view?key=AIzaSyBAKYu87V9ZFAeGzNB6_jnbAfaj0CXuCto&center=12.648806,77.619111&zoom=10&maptype=satellite"
						width="100%"
						height="100%"
						allowFullScreen
						loading="lazy"
						title="Google Map showing location at coordinates 12.648806, 77.619111"
						className="img-fluid"
					></iframe>
					{/* <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1306.4615053618027!2d77.588132085902!3d12.31529764602881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae930036f1a285%3A0x70e4fd54ffff0f48!2sJungle%20homes!5e1!3m2!1sen!2sin!4v1735641135738!5m2!1sen!2sin&zoom=2"
  width="100%"
  height="100%"
  style={{ border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/> */}
				</div>
				<div className="map_bottom">
					<img src={mapBottom} className="img-fluid" alt="Map-Bottom"></img>
				</div>
			</div>
			<div className="contact_third_container">
				<FooterPage />
			</div>
		</>
	);
};
export default Contact;
