import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import "../not-found/NotFound.css";

const NotFound = () => {
	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				position: "relative",
				top: 0,
				left: 0,
				backgroundColor: "#3c502b",
			}}
		>
			<Navbar />

			<div
				className="notFound"
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<div className="details">
					<h1>404 - Page Not Found</h1>
					<p>The page you are looking for does not exist.</p>
					<Link to="/">Go to Homepage</Link>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
